.ms-container {

  @include make-row();

  .ms-list {
    margin: 1rem 0;
    padding: 0;
    color: $primary;
  }

  .ms-selectable, .ms-selection {
    @include make-col();

    border: 1px solid $border-color;
    margin: 1rem;
    padding: 1rem;
    max-height: 20rem;
    overflow-y: auto;
    overflow-x: hidden;

    .ms-elem-selectable, .ms-elem-selection {

      // From .badge
      display: inline-block;
      padding: $badge-padding-y $badge-padding-x;
      @include font-size($badge-font-size);
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      @include border-radius($badge-border-radius);
      @include gradient-bg();

      // From .btn-dw-link
      border: 1px solid $dw-btn-link-border;
      background-color: $dw-btn-link-bg;
      color: $dw-btn-link-color;
      font-weight: $font-weight-normal;
      text-decoration: none;

      margin: .25rem;
      cursor: pointer;
    }

    .ms-elem-selectable:hover, .ms-elem-selection {
      color: $dw-btn-link-color !important;
      background-color: $dw-btn-link-bg-hover !important;
    }
  }
}
