.dz-border-edit
{
  outline: 2px dashed $primary;
}

.user-profile {
  margin-bottom: 70px;
}

#js-username-container {
  .twitter-typeahead {
    display: block !important;
  }
}

#dz-jumbotron-image
{
  background-size: cover;
  display: block;
  position: relative;
  height: 10rem;
  margin-bottom: 1rem;

  @include media-breakpoint-up("md") {
    height: 15rem;
  }

  .dw-has-online
  {
    height: 120px;
    top: calc(10rem - 90px);
    z-index: 1;

    @include media-breakpoint-up("md") {
      top: calc(15rem - 90px);
    }

    // Avatar dimensions
    img {
      width: 120px;
      height: 120px;
    }

    .dw-online
    {
      bottom: -70px;
    }
  }

  .dw-stats
  {
    position: absolute;
    top: 11rem;

    @include media-breakpoint-up("md") {
      top: 16rem;
    }
  }

}
