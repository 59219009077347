// Conversations

#js-conversations {

  &.dw-chat-list {
    top: $dw-brand-line-height * 2;
    height: calc(100% - $dw-brand-line-height * 2);
    position: fixed;
    left: 0;
    z-index: 4;
    border-right: 1px solid $border-color;

    @include media-breakpoint-up("xxl") {
      body.dw-show-sidebar & {
        left: 280px;
      }
    }

    @include media-breakpoint-down("sm") {
      width: 15px;

      #js-initial-sidebar {
        visibility: hidden;
      }

      &:hover {
        width: 82px;

        #js-initial-sidebar {
          visibility: visible;
        }

      }
    }

  }

}

&.dw-show-chat-list {

  .be-sub-navigation {
    top: $dw-brand-line-height;
    position: fixed;
    width: 100%;

    @include media-breakpoint-up("xxl") {
      body.dw-show-sidebar &
      {
        width: calc(100% - 280px) !important;
      }
    }

    h1 {
      width: 100%;
      text-align: center;
    }
  }

  .dw-chat-list + div {
    margin-left: 15px;
    margin-top: $dw-brand-line-height;

    @include media-breakpoint-up("sm") {
      margin-left: 82px;
    }

    @include media-breakpoint-up("md") {
      margin-left: 300px;
    }
  }
}
