div#dz-push-content
{
  position: relative;
  height: 100%;

  .dz-page-title
  {
    position: fixed;
    top: $dw-brand-line-height;
    min-height: $dw-brand-line-height;
    background-color: transparent;
    z-index: 5;
    left: 0;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;

    @include media-breakpoint-up("xxl") {
      body.dw-show-sidebar & {
        left: 280px;
        width: calc(100% - 280px);
      }
    }

    #js-page-title {
      line-height: $dw-brand-line-height;
    }
  }

  #dz-chat-container {
    background-color: $white;
    position: fixed;
    height: 100%;
    width: calc(100% - 15px);
    left: 15px;

    @include media-breakpoint-up("sm") {
      width: calc(100% - 82px);
      left: 82px;
    }

    @include media-breakpoint-up("md") {
      width: calc(100% - 300px);
      left: 300px;
    }

    @include media-breakpoint-up("xxl") {
      body.dw-show-sidebar &
      {
        width: calc(100% - 580px);
        left: 580px;
      }
    }

    #dz-chat {
      white-space: nowrap;
      height: calc(100% - $dw-brand-line-height * 2);
      left: 0;

      #js-chat-scroll {
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
      }

      ul#js-messages {

        padding: 0;
        margin: 0;

        li {
          display: block;
          list-style: none;
          padding: .75rem 1rem;

          &.self {
            text-align: right;
          }

          .dz-avatar {
            width: 45px;
            max-width: 45px;
          }

          &.self .msg > :not(blockquote) {

            color: $white;

            a {
              color: $gray-400;

              &:hover {
                color: $gray-500;
              }
            }
          }

          &.self .dz-msg-container {
            margin-left: 10rem;
            justify-content: flex-end;
            position: relative;

            @include media-breakpoint-down("lg") {
              margin-left: 4rem;
            }
          }

          &.friend .dz-msg-container {
            margin-right: 10rem;
            justify-content: flex-start;
            position: relative;

            @include media-breakpoint-down("lg") {
              margin-right: 4rem;
            }
          }

          .dz-msg-container {

            position: relative;
            display: flex;
            justify-content: flex-end;
            width: 100%;

            .dz-before-msg {
              position: relative;
              margin-right: .5rem;
            }

            .dz-after-msg {
              position: relative;
              margin-left: .5rem;
            }

            .js-last-seen {
              bottom: -1.25rem;
            }
          }

          .msg {
            position: relative;
            display: inline-block;
            background-color: $primary;
            padding: .5rem;
            border-radius: $border-radius;
            white-space: normal;
            text-align: left;
            word-break: break-word;
            justify-content: flex-end;

            a {
              text-decoration: underline;
            }

            p:last-child {
              margin-bottom: 0;
            }

            &:after {
              content: "";
              position: absolute;
              right: -5px;
              top: 7px;
              border-bottom: 6px solid transparent;
              border-left: 6px solid $primary;
              border-top: 6px solid transparent;
            }
          }

          &.friend .msg {

            background-color: $dw-gray-bg;
            color: $dark;
            justify-content: flex-start;

            &:after {
              right: auto;
              left: -5px;
              border-left-width: 0;
              border-right: 5px solid $dw-gray-bg;
            }
          }
        }

      }
    }
  }
}
