#js-meet-buttons {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 15px);
  z-index: 1;

  @include media-breakpoint-up("sm") {
    width: calc(100% - 82px);
  }

  @include media-breakpoint-up("md") {
    width: calc(100% - 300px);
  }

  @include media-breakpoint-up("xxl") {
    body.dw-show-sidebar & {
      width: calc(100% - 580px);
    }
  }
}
