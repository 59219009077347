// Breadcrumbs

@include media-breakpoint-down("md") {

  .breadcrumb li.breadcrumb-item {
    display: none;

    &:nth-last-child(2) {
      display: inline;
    }
  }
}
